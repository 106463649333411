import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import cover from "./hero-background.jpg";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage: `url(${cover})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'middle'
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 1, sm: 1},
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '85%' } }}>
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: 'clamp(3rem, 10vw, 4rem)',
              color: '#FFFFFF'}}
          >
            Research Integrity
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: 'clamp(3rem, 10vw, 4rem)',
              color: '#FFFFFF'}}
          >
            Made Easy
          </Typography>
          <Typography
            textAlign="left"
            color="#d9d9d9"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(2rem, 10vw, 2rem)' }}
          >
            Cutting-edge products, delivering integrity solutions for Scientific Publishing.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
