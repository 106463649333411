import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function AboutUs() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '85%' } }}>
        <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Scitility PBC
          </Typography>
         
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}
          >
            Scitility PBC is a pioneering technology company dedicated to improving science by addressing complex challenges within the scientific community. We specialize in creating digital utility tools and services that empower researchers, publishers, funders, and institutions.  At Scitility, we believe that technology and innovation should drive meaningful change, and our public benefit  status reinforces our dedication to improving the world and upholding high integrity standards.
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Sci - til - i - ty 
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(1rem, 10vw, 1rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.dark' : 'primary.contrastText',
              }}
            >
          
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}
          >
           Our name, a blend of "Science" and "Utility," reflects our commitment to creating reliable and impactful solutions that support the scientific community.  As a technology company we believe in providing essential, high quality, digital utility-grade tools.  At Scitility, we want to build key infrastructure components for a more efficient, sustainable and ultimately collaborative research ecosystem.
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Why is Scitility organized as a Public Benefit Corporation (PBC) and what does that mean?
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(1rem, 10vw, 1rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.dark' : 'primary.contrastText',
              }}
          >
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}
          >
            We chose to be a Public Benefit Corporation (PBC) to bridge the gap between traditional for-profit companies and nonprofits. PBCs pursue both public benefits and profits, this allows us to raise funds from investors while having a legal framework that prioritizes our social and environmental goals.
            </Typography>
            <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}
          >
          By being a PBC, we ensure that our mission to contribute to the community remains at the core of our business.
          </Typography>

          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Funding
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(1rem, 10vw, 1rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.dark' : 'primary.contrastText',
              }}
            >
          
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}
          >
            We’re proud to say that Scitility is powered by passion and ingenuity, not venture capital. As a  self-funded company, we’re driven by our love for science, technology and our commitment to serving the research community.  This allows us to have the freedom to focus solely on what matters most: building innovative, high-quality services that make a real impact.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
