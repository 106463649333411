import { alpha, Box, Container, Stack, Typography } from "@mui/material"

export default function Sept18PressRelease() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                    <Typography
                        component="span"
                        variant="h2"
                        sx={{
                            fontSize: 'clamp(1.4rem, 10vw, 1.4rem)',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.dark' : 'primary.contrastText',
                        }}
                    >
                        PRESS RELEASE - 19th September 2024
                    </Typography>
                    <Typography component="span"
                        variant="h1"
                        sx={{
                            fontSize: 'clamp(2rem, 10vw, 2rem)',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        Scitility PBC, a public benefit corporation, unveils argos, the revolutionary tool that makes visible the hidden patterns and cascading effects of retractions on scientific research.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Scitility PBC (<a href="https://www.scitility.com">www.scitility.com</a>), a technology first company whose mission is to improve scientific integrity, announced today the launch of its debut product: argos.
                        argos, a groundbreaking free-to-individual solution, that tackles the growing challenge of scientific malpractice. By organizing over a billion pieces of publicly available information, argos empowers publishers, researchers, institutions and funders in their goal of improving scientific integrity.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        With its ability to swiftly analyze vast amounts of retraction data, argos promises to transform the way the scientific community addresses the cascading effects of fraudulent activities. argos provides the tool to find the hidden patterns caused by retractions in order to improve trust and integrity in science.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Scitility PBC specializes in creating digital utility tools and services that empower researchers, publishers, funders, and institutions.  At Scitility, we believe that technology and innovation should drive meaningful change, and as a  public benefit corporation, we are dedicated to improving the world while upholding the highest integrity standards.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Gary Cornell PhD, one of the three founders of Scitility PBC said: <em>"We're excited to introduce argos to the scientific community. We have worked tirelessly to develop an easy to use solution that meets the needs of this community. We are sure argos will be a necessary tool for strengthening scientific integrity.”</em>
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Key benefits of argos include:
                        <ol>
                            <li><strong>Identify problematic articles before publication.</strong> Safeguard your reputation. Easily integrate argos into your workflow with our API and automate integrity checks directly after submission.</li>
                            <li><strong>Find “those needles in a haystack.”</strong> Analyzes over 1 billion citations and tracks more than 50 million articles to see the retracted articles that can impact your portfolio.</li>
                            <li><strong>On Demand Risk Assessment:</strong> Get an immediate risk assessment of articles in your portfolio, identify citation rings and potential papermill activities.</li>
                        </ol>
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Try the basic features of argos at https://www.scitility.com/argos . For advanced features, please contact us (<a href="mailto:hello@scitility.com">hello@scitility.com</a>) for an institutional demo account.
                    </Typography>
                    <Typography
                        textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.1rem, 10vw, 1.1rem)' }}>
                        About Scitility PBC: Founded in 2024, Scitility PBC is a public benefit corporation dedicated to improving scientific integrity. PBCs, like Scitility PBC, have a legal mandate that allows them to prioritize their benefits to society, in our case, the goal of improving research integrity!
                        For media inquiries, please contact: Gary Cornell, Founder: <a href="mailto:gary@scitility.com">gary@scitility.com</a>
                    </Typography>
                </Stack>
            </Container></Box>
    )
}