import { alpha, Box, Container, Stack, Typography } from "@mui/material"

export default function RiskyBusiness() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                    <Typography
                        component="span"
                        variant="h2"
                        sx={{
                            fontSize: 'clamp(1.4rem, 10vw, 1.4rem)',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.dark' : 'primary.contrastText',
                        }}
                    >
                        24th September 2024
                    </Typography>
                    <Typography component="span"
                        variant="h1"
                        sx={{
                            fontSize: '2rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        Risky Business!
                    </Typography>
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 1,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        TL;DR
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        argos analyzes information from several different sources and then calculates daily a risk score for all articles published since Jan 1st 2014. We take into account the reason for the retraction, its citations, and the history of authors. From this data and more we create a DAG (Directed acyclic graph) which allows us to calculate “risk” based on the observable and measurable level of impact from previous problematic retractions. This allows us to help you find those needles in the haystack!
                    </Typography>
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 0,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        Just the facts of argos
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{
                            alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)'
                        }}>
                        <ul>
                            <li><strong>Huge dataset</strong>: analyzes over 1 billion citations, 50 million articles and 95 million author profiles.</li>
                            <li><strong>Up to date</strong>: Argos ingests any new information and reevaluates the Scitility Risk Score daily.</li>
                            <li><strong>Explainable results</strong>: What supports the calculation of the Scitility Risk Score is clear and we provide a full explanation to our users.</li>
                        </ul>
                    </Typography>
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 0,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        Creation of a Scitility Risk Score
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{
                            alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)'
                        }}>
                        argos has created a “Scitility Risk Score” for all published articles since Jan 1st 2014.  We report three levels of risk: low risk, medium risk and high risk. We calculate the Scitility Risk Score by looking at the cascading effect of retractions on authors and citations. We always base our analysis on facts that we can show to our users. For example, we take into account the reasons for the retraction. We only use information that our algorithm can confidently connect to an article, because after all, sources of information in particular and the scientific record in general is imperfect. We have made every effort to increase the quality of our raw data and we will keep improving it. We aim to differentiate authors who have normal retractions that are part of the scientific discourse from those who have retractions that are clearly problematic.
                        Transparency and explainability are the key characteristics of the Scitility Risk Score!
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        It is important to also highlight what we do not do: we do not include age, gender, sexual orientation, political beliefs or nationality in our models. We use a consistent approach to evaluate every single article on our dataset. <em>We are making every effort to create a fair model for the Scientific community.</em>
                    </Typography>
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 0,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        What does having a High Scitility Risk score mean?
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Having a high Scitility Risk Score indicates that the authorship and/or citations are impacted by the cascading effect of retractions. For the authorship impact, we only look at situations that are of great concern; like ethical violations, falsification/fabrication of results, fake peer review, paper mill product, manipulation of images or even randomly generated content. We believe a high risk score justifies further analysis and so we flag them. In the end though, you must do that investigation. A high risk score is a warning flag not proof positive: the Scitility Risk Score does not tell you about the quality of the article.
                    </Typography>
                </Stack>
            </Container></Box>
    )
}